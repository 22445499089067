import './App.css';
import './components/common/Style.css';
import Top from './components/top/Top';
import Company from './components/company/Company';
import Business from './components/business/Business';
import Recruit from './components/recruit/Recruit';
import Contact from './components/contact/Contact';
import Privacy from './components/privacy/Privacy';
import Confirm from './components/contact/Confirm';
import Complete from './components/contact/Complete';
import Nav from './components/nav/index';
import Footer from './components/footer/index';
import { BrowserRouter, Routes, Route } from 'react-router-dom'; 
import { BrowserUpdated } from '@mui/icons-material';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Nav />
          <Routes>
            <Route path="/" element={<Top />} />
            <Route path="/Company" element={<Company />} />
            <Route path="/Business" element={<Business />} />
            <Route path="/Recruit" element={<Recruit />} />
            <Route path="/Privacy" element={<Privacy />} />
            <Route path="/Contact" element={<Contact />} />
            <Route path="/Contact/Confirm" element={<Confirm />} />
            <Route path="/Contact/Complete" element={<Complete />} />

          </Routes>
        <Footer />
      </BrowserRouter>
    </div>    
  );
}

export default App;
