import React from "react";
import './Recruit.css';
import AnchorLink from "react-anchor-link-smooth-scroll"

const Recruit = () => {
    return (
        <div className="Recruit pg_inner bg_screen1">
            <section className="sec bg_page1">
                <h1>採用情報</h1>
                <p className="txt">TryTreeでいっしょに働きませんか？</p>
                <h2>募集職種</h2>
                <section className="category">
                    <h3>システムエンジニア</h3>
                    <dl>
                        <dt>給与</dt>
                        <dd>
                            <p><b>月給35万円～100万円</b></p>
                            <p>※固定残業代（20時間分）含む。<br className="pcno" />超過分は別途支給。</p><br className="pcno" />
                            <p>これまでの経験やスキルをしっかりと評価します！<br />
                            能力に応じて入社時からリーダーへの配属もございます。</p><br className="pcno" />
                            <p className="anchor"><AnchorLink href="#link">募集要項はこちら</AnchorLink></p>
                        </dd>
                    </dl>
                </section>
                <section className="category">
                    <h3>ITサポート</h3>
                    <dl>
                        <dt>給与</dt>
                        <dd>
                            <p><b>月給25万～</b></p>
                            <p>※固定残業代（20時間分）含む。<br className="pcno" />超過分は別途支給。</p><br className="pcno" />
                            <p>業務経験に応じてスキルアップも可能です。</p><br className="pcno" />
                            <p className="anchor"><AnchorLink href="#link">募集要項はこちら</AnchorLink></p>
                        </dd>
                    </dl>
                </section>
                <h2 id="link">募集要項</h2>
                <dl className="requirement">
                    <dt>職務内容</dt>
                    <dd>
                        <p>プロジェクトの立ち上げからリリースまで一貫して携わっており、お客様に本当に喜ばれる技術提供を行っています。<br />
                        Web業務システムやサイト開発、スマートフォンアプリ開発など、幅広い領域でシステム開発に従事していただきます。</p>
                    </dd>
                    <dt>勤務地</dt>
                    <dd>本社並びに支社 在宅 案件によっては客先</dd>
                    <dt>勤務時間</dt>
                    <dd>9:00～18:00</dd>
                    <dt>昇給</dt>
                    <dd>年1回（9月末）</dd>
                    <dt>諸手当</dt>
                    <dd>
                        <ul>
                            <li>社会保険完備</li>
                            <li>交通費支給</li>
                            <li>Myインセンティブ制度あり</li>
                            <li>スキルアップ支援制度あり</li>
                            <li>資格取得支援あり</li>
                            <li>農業体験/研修制度（アグトレ）あり</li>
                            <li>産休/育休制度あり</li>
                            <li>家族みまもり休暇あり</li>
                            <li>長期休暇あり</li>
                            <li>通院休暇あり</li>
                            <li>生理休暇（有給）あり</li>
                            <li>在宅手当あり</li>
                            <li>KIREI手当あり</li>
                            <li>即日勤務OK</li>
                            <li>残業月20時間以内</li>
                        </ul>
                    </dd>
                </dl>
                <div className="others">
                    <p>採用に関する情報は以下サイトでも掲載中です。</p>
                    <a className="banner" href="https://en-gage.net/trytree_saiyo/?banner=1" target="_blank"><img src="https://en-gage.net/imageFile_new/company/widget_banner_B_pc_300×250.png" alt="engage" border="0"/></a>
                    <a className="banner" href="https://www.wantedly.com/companies/company_4176836" target="_blank"><img src="/img/img_wantedlyLogoLightBG.png" alt="wantedly" /></a>
                </div>
            </section>
        </div>
    )
}
export default Recruit;
