import React from "react";
import './Privacy.css';

const Privacy = () => {
    return (
        <div className="privacy pg_inner bg_screen1">
            <section className="sec bg_page1">
                <h1>プライバシーポリシー</h1>
                <div className="info">
                    <p>株式会社TryTree（以下、「当社」という）は、ソフトウェア開発業務の請負、
                    委託関連業務を遂行するにあたり、個人情報を取り扱うことがあります。<br />
                    そこで当社では、この個人情報保護方針に基づき、個人情報保護マネジメントシステムを確立するとともに実行し、
                    事業活動にかかわるすべての従業員が一体となって、個人情報の適切な取り扱いを実現いたします。
                    </p>
                    <ul>
                        <li>株式会社 TryTree</li>
                        <li>代表取締役 二ノ宮 銀</li>
                    </ul>
                </div>
                <dl className="rule">
                    <dt>【1．事業者情報】</dt>
                    <dd>法人名：株式会社TryTree<br/>
                        住所：〒943-0861 新潟県上越市大和5丁目2-7 エンジョイプラザ2F<br />
                        代表取締役：二ノ宮 銀
                    </dd>
                    <dt>【2．法令等の遵守】</dt>
                    <dd>当社は、個人情報の保護に関する法令およびその他の規範を遵守し、個人情報を適正に取り扱いたします。</dd>
                    <dt>【3．個人情報の取得・利用】</dt>
                    <dd>当社では、個人情報の漏洩、滅失、き損等を防止するための適切なセキュリティ対策を実施しております。<br />
                    これらを徹底するため、従業員への教育・監督をおこなっております。<br />
                    個人情報取り扱い業務を社外に委託する場合は、委託先の選定や監督を厳重におこなっております。<br />
                    また、当社が取得した個人情報は、厳重なる管理の上、以下の範囲内で取扱い、目的外の利用はいたしません。<br />
                    <ul className="list">
                        <li>
                            <dl>
                                <dt>お問い合わせに関する個人情報の利用目的</dt>
                                <dd>
                                    <ol>
                                        <li>お問い合わせに関する回答の連絡のため</li>
                                    </ol>
                                </dd>
                            </dl>
                        </li>
                        <li>
                            <dl>
                                <dt>やろっさ！（田んぼ区画販売サービス）に関する個人情報の利用目的</dt>
                                <dd>
                                    <ol>
                                        <li>当社サービスの提供、運用のため</li>
                                        <li>当社サービスの新機能、更新情報、キャンペーン等及び当社が提供する他のサービスの案内のメールを送付するため</li>
                                        <li>当社サービスのメンテナンス、重要なお知らせなど必要に応じたご連絡のため</li>
                                        <li>利用規約に違反したユーザや、不正・不当な目的でのユーザを特定し、<br/>ご利用をお断りするため</li>
                                        <li>ユーザにご自身の登録情報の閲覧や変更、削除、ご利用状況の閲覧を行っていただくため</li>
                                        <li>有料サービスにおいて、ユーザに利用料金を請求するため</li>
                                        <li>当社及び商品発送を行う事業者が商品を発送するため</li>
                                    </ol>
                                </dd>
                            </dl>
                        </li>
                        <li>
                            <dl>
                                <dt>採用募集者に関する個人情報の利用目的</dt>
                                <dd>
                                    <ol>
                                        <li>採用選考のため</li>
                                        <li>採用選考手続きおよびその結果に関する連絡のため</li>
                                        <li>入社前手続き実施とその他の手続きに関する連絡のため</li>
                                    </ol>
                                </dd>
                            </dl>
                        </li>
                        <li>
                            <dl>
                                <dt>当社が、提供元・委託元、また協力会社から個人情報をお預かりした場合は、厳重なる管理の上、利用目的の範囲内で取扱い、目的外の利用はいたしません。</dt>
                                <dd>
                                    <ol>
                                        <li>業務上の商談・打ち合わせの実施、および連絡などの目的</li>
                                        <li>取引先への情報提供、および連絡などの目的</li>
                                        <li>受託取引先から委託された業務の実施などの目的</li>
                                    </ol>
                                </dd>
                            </dl>
                        </li>
                    </ul>
                    </dd>
                    <dt>【4．個人データを安全に管理するための措置】</dt>
                    <dd>当社は個人情報を正確かつ最新の内容に保つよう努め、不正なアクセス・改ざん・漏えい・滅失及び毀損から保護するため全従業員及び役員に対して教育研修を実施しております。<br/>
                        また、個人情報保護規程を設け、現場での管理についても定期的に点検を行っております。
                    </dd>
                    <dt>【5．個人情報保護マネジメントシステムの継続的改善】</dt>
                    <dd>当社は、個人情報の保護に関するマネジメントシステムを実行し、維持するとともに定期的に見直しをおこない、継続的改善に努めます。</dd>
                    <dt>【6．個人データの第三者提供について】</dt>
                    <dd>当社は法令及びガイドラインに別段の定めがある場合を除き、同意を得ないで第三者に個人情報を提供することは致しません。</dd>
                    <dt>【7．開示・訂正・利用停止等の手続き】</dt>
                    <dd>当社は、当社が保有している個人情報の開示をご本人から求められた際は、所定の手続きに基づいて開示いたします。その結果、誤った情報があった場合は、速やかに訂正・削除いたします。</dd>
                    <dt>【8．個人情報取り扱いに関する相談や苦情の連絡先】</dt>
                    <dd>当社の個人情報の取り扱いに関するご質問やご不明点、苦情、その他のお問い合わせは以下よりご連絡ください。<br />
                    【個人情報お問い合わせ窓口】<br />
                    電話：025-512-2702<br />
                    メール：it_info@trytree.co.jp<br />
                    </dd>
                    <dt>【9．SSL（Secure Socket Layer）について】</dt>
                    <dd>当社のWebサイトはSSLに対応しており、WebブラウザとWebサーバーとの通信を暗号化しております。</dd>
                    <dt>【10．cookieについて】</dt>
                    <dd>cookieとは、WebサーバーからWebブラウザに送信されるデータのことです。<br />
                    Webサーバーがcookieを参照することでユーザーのパソコンを識別でき、効率的に当社Webサイトを利用することができます。<br />
                    当社Webサイトがcookieとして送るファイルは、個人を特定するような情報は含んでおりません。<br />
                    お使いのWebブラウザの設定により、cookieを無効にすることも可能です。</dd>
                    <dt>【11．免責事項】</dt>
                    <dd>当社Webサイトに掲載されている情報の正確性には万全を期していますが、利用者が当社Webサイトの情報を用いて行う一切の行為に関して、一切の責任を負わないものといたします。<br />
                    当社は、利用者が当社Webサイトを利用したことにより生じた利用者の損害及び利用者が第三者に与えた損害に関して、一切の責任を負わないものといたします。
                    </dd>
                    <dt>【12．著作権・肖像権】</dt>
                    <dd>当社Webサイト内の文章や画像、すべてのコンテンツは著作権・肖像権等により保護されています。無断での使用や転用は禁止されています。</dd>
                    <dt>【13．リンク】</dt>
                    <dd>当社Webサイトへのリンクは、自由に設置していただいて構いません。<br />
                    ただし、Webサイトの内容等によってはリンクの設置をお断りすることがあります。
                    </dd>
                </dl>
            </section>
        </div>
    )
}

export default Privacy;
