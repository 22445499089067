import React from "react";
import './Top.css';

const Top = () => {
    return (
        <div className="Top">
            <div className="t_inner">
                <p className="catch spno"><img src="/img/img_catch.png" alt="やってみたいをカタチにする。" /></p>
                <div className="slide">
                    <ul>
                        <li><img src="/img/img_slide1.png" alt="slide1" /></li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default Top;
