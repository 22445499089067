import React, {useEffect} from "react";
import {useLocation, useNavigate} from 'react-router-dom';

const Complete = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const handleSubmit = () => {
        navigate('/');
    }
    useEffect(() => {
        if(!location.state){
            navigate('/');
            return;
        }
    },[]);
    return (
        <div className="complete pg_inner bg_screen1">
            <section className="sec bg_page1">
                <h1>お問い合わせ<br/><span>送信完了</span></h1>
                <div className="msg">
                    <p>メッセージが送信されました。</p>
                    <p>改めて担当者よりご連絡をさせていただきます。</p><br />
                    <p>今しばらくお待ちくださいますよう、お願い申し上げます。</p><br />
                    <p>なお、しばらくたっても返信、返答がない場合はお客様によりご入力いただいたメールアドレスに誤りがある場合がございます。</p><br/>
                    <p>その際は、お手数ですが再度送信いただくか、<br className="spno"/>お電話（ 025-512-2702 ）にてご連絡いただけますと幸いです。</p>
                </div>
                <div className="btn_block">
                    <button className="btn" onClick={handleSubmit}>TOPへ戻る</button>
                </div>
            </section>
        </div>
    )
}

export default Complete