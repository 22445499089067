import React from "react";
import './Business.css';

const Business = () => {
    return (
        <div className="Business pg_inner bg_screen1">
            <section className="sec bg_page1">
                <h1>事業内容</h1>
                <article className="cont">
                    <div className="img"><img src="/img/img_system.png" alt="システムインテグレーション事業イメージ" /></div>
                    <div className="blk">
                        <h2>システムインテグレーション事業</h2>
                        <p>ITに関わる課題に対して自社のエンジニア、パートナーリソースを活用し、請負、委託での支援を行います。</p>
                        <div className="achievements">
                            <h3>実績</h3>
                            <ul>
                                <li>官公庁向け道路交通データプラットフォーム開発</li>
                                <li>某商社海外拠点移設に伴うNW構築</li>
                                <li>某省庁様向けAIOCRシステム開発</li>
                                <li>不動産賃貸管理システムのクラウド環境構築、移行</li>
                            </ul>
                        </div>
                    </div>
                </article>
                <article className="cont cont_reverse">
                    <div className="img"><img src="/img/img_regional _revitalization2.png" alt="地方創生事業イメージ" /></div>
                    <div className="blk">
                        <h2>地方創生事業</h2>
                        <p>地方の抱える様々な課題を解消し地場の魅力を活かしながら、本質的な地域活性化を支援します。</p>
                        <p className="sns"><a href="https://www.instagram.com/trytree_okome_/" target="_blank"><img src="/img/icon_Instagram_Glyph_Gradient.png" alt="instagramアイコン"/>TryTreeの田んぼについて</a></p>
                        <div className="achievements">
                            <h3>実績</h3>
                            <ul>
                                <li>棚田を活用した棚田米の制作、発信、販路開拓、商品開発、サービス開発「やろっさ！」</li>
                                <li>某高校におけるプログラミング教育支援</li>
                                <li>某職業訓練校ITリスキリング講座における企画・運営・広報制作・講座実施（受託事業）</li>
                                <li>SNSを活用した地域情報の発信</li>
                            </ul>
                        </div>
                    </div>
                </article>
                <article className="cont">
                    <div className="img"><img src="/img/img_consulting.png" alt="組織コンサルティング事業イメージ" /></div>
                    <div className="blk">
                        <h2>組織コンサルティング事業</h2>
                        <p>組織の活性化と長期的な成長の為のアドバイスを行います。指示出し、定点観測だけではなく伴走も行い、組織の自立を促します。</p>
                    </div>
                </article>
            </section>
        </div>
    )
}

export default Business;
