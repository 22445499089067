import React, {useEffect, useState} from "react";
import {useLocation, useNavigate} from 'react-router-dom';
import './Contact.css';

const Contact = () => {
    const [loading, setLoading] = useState(false);
    const [company, setCompany] = useState('');
    const [name, setName] = useState('');
    const [tel, setTel] = useState('');
    const [email, setEmail] = useState('');
    const [contact, setContact] = useState('');
    const [message, setMessage] = useState('');
    const [errors, setErrors] = useState({});
    const location = useLocation();
    const navigate = useNavigate();
    const search = useLocation().search;

    useEffect(() => {
        const query = new URLSearchParams(search);
        const contactType = query.get('contact_type');

        if(contactType === 'tanbo') {
            setContact("やろっさ！（田んぼ区画販売サービス）");
        }


        if(location.state){
            setCompany(location.state.company);
            setName(location.state.name);
            setTel(location.state.tel);
            setEmail(location.state.email);
            setContact(location.state.contact);
            setMessage(location.state.message);
            setLoading(false);
        }
    },[])

    const validate = () => {
        const errors = {};

        if(!name){
            errors.name = '名前を入力してください。';
        }
        if(!tel) {
            errors.tel = '電話番号を入力してください。';
        } else if (!/^0\d{1,3}\d{2,4}\d{3,4}$/.test(tel) && !/^0\d{1,3}-\d{2,4}-\d{3,4}$/.test(tel)) {
            errors.tel = '有効な電話番号を入力してください。';
        }
        if(!email) {
            errors.email = 'メールアドレスを入力してください。';
        } else if (!/\S+@\S+\.\S+/.test(email)) {
            errors.email = '有効なメールアドレスを入力してください。';
        }
        if(!contact) {
            errors.contact = 'お問い合わせ項目を選択してください。';
        }

        setErrors(errors);

        return Object.keys(errors).length === 0;
    };

    const returnTop = () => {
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
    };

    const handleSubmit = async (event) => {
        setLoading(true);
        event.preventDefault();


        returnTop();

        // 入力項目チェック
        if (!validate()) {
            setLoading(false);
            return;
        }

        const data = {
            company: company,
            name: name,
            tel: tel,
            email: email,
            contact: contact,
            message: message,
        }
        navigate('/contact/confirm', { state: data });
    }
    return (
        <div className="contact pg_inner bg_screen1">
            <section className="sec bg_page1">
                <h1>お問い合わせ</h1>
                <form onSubmit={(e) => handleSubmit(e)}>
                    <div className="msg">
                        <p>お問い合わせはこちらから<br /><small>*…必須項目</small></p>
                        {errors.name && <p className="error">{errors.name}</p>}
                        {errors.tel && <p className="error">{errors.tel}</p>}
                        {errors.email && <p className="error">{errors.email}</p>}
                        {errors.contact && <p className="error">{errors.contact}</p>}
                    </div>
                    <dl className="form_block">
                        <dt>会社名</dt>
                        <dd><input
                            type="text"
                            id="company"
                            value={company}
                            onChange={(event) => setCompany(event.target.value)}
                        /></dd>
                        <dt>氏名<span className="compulsory">*</span></dt>
                        <dd><input
                            type="text"
                            id="name"
                            value={name}
                            onChange={(event) => setName(event.target.value)}
                        /></dd>
                        <dt>電話番号<span className="compulsory">*</span></dt>
                        <dd><input
                            type="text"
                            id="tel"
                            value={tel}
                            onChange={(event) => setTel(event.target.value)}
                        /></dd>
                        <dt>メールアドレス<span className="compulsory">*</span></dt>
                        <dd><input
                            type="text"
                            id="email"
                            value={email}
                            onChange={(event) => setEmail(event.target.value)}
                        /></dd>
                        <dt>お問い合わせ項目<span className="compulsory">*</span></dt>
                        <dd>
                            <select
                                className="select_item"
                                value={contact}
                                onChange={(event) => setContact(event.target.value)}>
                                <option value="">選択してください</option>
                                <option value="システムソリューション事業について">システムソリューション事業について</option>
                                <option value="地方創生事業について">地方創生事業について</option>
                                <option value="組織コンサルティング事業について">組織コンサルティング事業について</option>
                                <option value="やろっさ！（田んぼ区画販売サービス）">やろっさ！（田んぼ区画販売サービス）</option>
                                <option value="その他">その他</option>
                            </select>
                        </dd>
                        <dt>お問い合わせ内容</dt>
                        <dd><textarea
                            type="text"
                            id="message"
                            value={message}
                            onChange={(event) => setMessage(event.target.value)}
                            rows={7}
                        /></dd>
                    </dl>
                    <div>
                        {loading &&
                            <div className="btn_block">
                                <button className="submit btn" disabled={loading}>
                                    送信中
                                </button>
                            </div>
                        }
                        {!loading &&
                            <div className="btn_block">
                                <button className="submit btn"　onClick={handleSubmit} disabled={loading}>
                                    送信
                                </button>
                            </div>
                        }
                    </div>
                </form>
            </section>
        </div>
            )
}

export default Contact;
