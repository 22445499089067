import React, { useState } from 'react';
import {List, Drawer, ListItem, ListItemButton, ListItemText, Link } from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import './Nav.css';
import MediaQuery from "react-responsive";

const Nav = () => {
    const [open, setOpen] = useState(false);

    const handleDrawerOpen = () => {
        setOpen(true);
    }

    const handleDrawerClose = () => {
        setOpen(false);
    }

    return (
        <div className="header">
            <MediaQuery query="(min-width: 767px)">
            <a href="./" className="logo"><img src="/img/logo.png" /></a>
            <nav className="nav">
                <ul>
                    <li><a href="/Company">会社情報</a></li>
                    <li><a href="/Business">事業内容</a></li>
                    <li><a href="/Recruit">採用情報</a></li>
                    <li><a href="/Contact">お問い合わせ</a></li>
                </ul>
            </nav>
            </MediaQuery>
            <MediaQuery query="(max-width: 767px)">
            <a href="./" className="logo"><img src="/img/logo.png" /></a>
            <ListItem disablePadding className='open_menu'>
                <ListItemButton onClick={handleDrawerOpen} sx={{ display: { xs: 'block', md: 'none' } }} >
                    <ListItemText primary={<MenuIcon />} className='open_icon'/>
                </ListItemButton>
            </ListItem>
            <Drawer anchor="right" open={open} onClose={handleDrawerClose} PaperProps={{ style: { width: '50%' } }}>
                <List component="nav" disablePadding className='list_item' >
                    <ListItem disablePadding>
                        <ListItemButton onClick={handleDrawerClose}>
                            <ListItemText primary={<CloseIcon />} className='close_icon' />
                        </ListItemButton>
                    </ListItem>
                    <Link href="Company" underline="none">
                        <ListItemText primary="会社情報" />
                    </Link>
                    <Link href="Business" underline="none">
                        <ListItemText primary="事業内容" />
                    </Link>
                    <Link href="Recruit" underline="none">
                        <ListItemText primary="採用情報" />
                    </Link>
                    <Link href="Contact" underline="none">
                        <ListItemText primary="お問い合わせ" />
                    </Link>
                </List>
            </Drawer>
            </MediaQuery>
        </div>
    )
}
export default Nav;