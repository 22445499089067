import React, {useEffect, useState} from "react";
import {useLocation, useNavigate} from 'react-router-dom';
import axios from "axios";
import './Contact.css';

const Confirm = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState('');
    const [data, setData] = useState([]);

    useEffect(() => {
        if(!location.state){
            navigate('/');
            return;
        }
        setData({
            company: location.state.company,
            name: location.state.name,
            tel: location.state.tel,
            email: location.state.email,
            contact: location.state.contact,
            message: location.state.message
        })
        setMessage(location.state.message);
    },[]);

    const returnTop = () => {
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
    };

    const handleSubmitReturn = () => {
        navigate('/contact', { state: data });
        returnTop();
    }

    const handleSubmitSendMail = async(event) => {
        setLoading(true);
        event.preventDefault();

        // 動作確認でメール送信したい場合は以下のコメントアウトを外す。
        const res = await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/contact`, data);

        if(res.status != 200) {
            setLoading(false);
            console.error('response.status:', res.status);
            console.error('response.statusText:', res.statusText);
            throw new Error(res.statusText);
        }

        navigate('/contact/complete', { state: data });
    }
    return (
        <div className="confirm pg_inner bg_screen1">
        <section className="sec bg_page1">
            <h1>お問い合わせ<br/><span>入力内容確認</span></h1>
            <div className="msg">
                <p>以下の内容で送信してよろしいでしょうか？</p>
            </div>
            <dl className="form_block">
                <dt>会社名</dt>
                <dd>{data.company}</dd>
                <dt>氏名</dt>
                <dd>{data.name}</dd>
                <dt>電話番号</dt>
                <dd>{data.tel}</dd>
                <dt>メールアドレス</dt>
                <dd>{data.email}</dd>
                <dt>お問い合わせ項目</dt>
                <dd>{data.contact}</dd>
                <dt>お問い合わせ内容</dt>
                <dd>{message.split('\n').map(t => (
                    t !== '' ? <div>{t}</div> : <br/>
                ))}</dd>
            </dl>
            <div className="btn_block">
                <button className="btn" onClick={handleSubmitReturn}>戻る</button>
                {loading &&
                    <button className="send btn" disabled={loading}>
                        送信中
                    </button>
                }
                {!loading &&
                    <button className="submit btn"　onClick={handleSubmitSendMail} disabled={loading}>
                        送信
                    </button>
                }
            </div>
            </section>
        </div>
    )
}

export default Confirm;
