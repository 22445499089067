import React from "react";
import './Footer.css';
import MediaQuery from "react-responsive";

const Footer = () => {
    return (
            <footer>
                <div className="f_inner">
                    <a href="./" className="f_logo"><img src="/img/logo.png" alt="logo"/></a>
                    <address className="f_add">
                        <dl>    
                            <dt>本社</dt>
                            <dd>〒943-0861<br className="spno" />
                            新潟県上越市大和5丁目2-7<br />
                            エンジョイプラザ2F
                            </dd>
                            <dt>東京支社</dt>
                            <dd>〒160-0022<br className="spno" />
                            東京都新宿区新宿2-11-7<br />
                            第33宮庭ビル 5F
                            </dd>
                        </dl>
                        <div className="f_link">
                            <a href="https://www.instagram.com/trytree_okome_/" className="f_icon" target="_blank"><img src="/img/icon_Instagram_Glyph_Gradient.png" alt="instagramアイコン"/></a>
                            <a href="https://www.wantedly.com/companies/company_4176836" className="f_icon" target="_blank"><img src="/img/icon_Wantedly_Mark_DarkBG.png" alt="wantedlyアイコン"/></a>
                        </div>
                    </address>
                    <ul className="f_nav">
                        <li className="spno"><a href="/Company">会社情報</a></li>
                        <li className="spno"><a href="/Business">事業内容</a></li>
                        <li className="spno"><a href="/Recruit">採用情報</a></li>
                        <li className="spno"><a href="/Contact">お問い合わせ</a></li>
                        <li><a href="/Privacy">プライバシーポリシー</a></li>
                    </ul>
                </div>
                <p className="copy"><small>&copy;株式会社TryTree 2023</small></p>
            </footer>
        )
}
export default Footer;